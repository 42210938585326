import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';

function Login({ setLoggedIn, setToken, setPlbToken, setUserId }) {  // Add setPlbToken for the second token
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Set FileMaker server details
  const serverUrlAgenda = 'https://jt.cialona.nl/fmi/data/vLatest/databases/CI_Agenda';
  const serverUrlPlb = 'https://jt.cialona.nl/fmi/data/vLatest/databases/CI_PLB';
  const fmUsername = 'Admin';  // Replace with your FileMaker username
  const fmPassword = 'DuncanTrends12345!!';  // Replace with your FileMaker password

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Step 1: Log into CI_Agenda and get the API token using Basic Authentication
      const credentials = btoa(`${fmUsername}:${fmPassword}`);
      const loginResponseAgenda = await axios.post(`${serverUrlAgenda}/sessions`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${credentials}`
        }
      });

      const tokenAgenda = loginResponseAgenda.data.response.token;
      console.log('CI_Agenda token:', tokenAgenda);

      // Step 2: Log into CI_PLB and get a separate token
      const loginResponsePlb = await axios.post(`${serverUrlPlb}/sessions`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${credentials}`
        }
      });

      const tokenPlb = loginResponsePlb.data.response.token;
      console.log('CI_PLB token:', tokenPlb);

      // Step 3: Call the 'Uren_Login' script on CI_Agenda to verify user credentials
      const result = await axios.get(`${serverUrlAgenda}/layouts/Medewerker/script/Uren_Login`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenAgenda}`
        },
        params: {
          'script.param': JSON.stringify({
            username: username,
            password: password
          })
        }
      });

      console.log("Script result:", result.data);

      // Assuming a successful login is represented by scriptResult === '1'
      if (result.data.response.scriptResult === '1') {
        setToken(tokenAgenda);      // Save token for CI_Agenda
        setPlbToken(tokenPlb);      // Save token for CI_PLB
        setUserId(username);        // Store the username instead of userId
        setLoggedIn(true);          // Login successful
        setErrorMessage('');        // Clear any previous error messages
      } else {
        setErrorMessage('Incorrect username or password');
      }
    } catch (error) {
      console.error('Login failed', error);
      setErrorMessage(`Login failed: ${error.message}`);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        <h2>Uren Registratie</h2>
        <div className="input-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Gebruikersnaam"
          />
        </div>

        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Wachtwoord"
          />
        </div>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <button type="submit" className="login-button">Login</button>
      </form>
    </div>
  );
}

export default Login;
