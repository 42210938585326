import React, { useState } from 'react';
import Login from './Login';
import RegisterHours from './RegisterHours';
import PlannedHours from './plannedHours ';
import './App.css';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [token, setToken] = useState('');
  const [plbToken, setPlbToken] = useState('');
  const [currentPage, setCurrentPage] = useState('register');

  if (!loggedIn) {
    return (
      <Login 
        setLoggedIn={setLoggedIn} 
        setToken={setToken} 
        setPlbToken={setPlbToken} 
        setUserId={setUsername} 
      />
    );
  }

  return (
    <div className="app-container">
      <nav className="menu">
        <button onClick={() => setCurrentPage('register')} className={`menu-button ${currentPage === 'register' ? 'active' : ''}`}>
          URENREGISTRATIE
        </button>
        <button onClick={() => setCurrentPage('planned')} className={`menu-button ${currentPage === 'planned' ? 'active' : ''}`}>
          PLANNING
        </button>
      </nav>

      {currentPage === 'register' && (
        <RegisterHours 
  userId={username} 
  selectedDate={selectedDate} 
  setSelectedDate={setSelectedDate}
  token={token}
  plbToken={plbToken}  // Pass plbToken here
/>

      )}
      {currentPage === 'planned' && (
        <PlannedHours 
          userId={username} 
          token={plbToken}
          selectedDate={selectedDate}  // Pass selectedDate to PlannedHours
        />
      )}
    </div>
  );
}

export default App;
