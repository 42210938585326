import React, { useEffect, useState, useRef } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/nl';
import axios from 'axios';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './plannedHours.css';

moment.locale('nl');
const localizer = momentLocalizer(moment);

function PlannedHours({ userId, token, selectedDate }) {
  const [plannedEvents, setPlannedEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); // State to track clicked event
  const fetchedDate = useRef(null); // Tracks if data has been fetched for a specific date

  useEffect(() => {
    if (fetchedDate.current === selectedDate.toISOString()) return;
    fetchedDate.current = selectedDate.toISOString();

    const fetchPlannedHours = async () => {
      const startDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1, 1);
      const formattedStartDate = `${String(startDate.getDate()).padStart(2, '0')}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${startDate.getFullYear()}`;

      const endDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 2, 0);
      const formattedEndDate = `${String(endDate.getDate()).padStart(2, '0')}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${endDate.getFullYear()}`;

      try {
        const response = await axios.get('https://jt.cialona.nl/fmi/data/vLatest/databases/CI_PLB/layouts/ENDPOINTEVT/script/APP_GETUREN', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          params: {
            'script.param': JSON.stringify({
              userId,
              startDate: formattedStartDate,
              endDate: formattedEndDate
            })
          }
        });

        const resultData = JSON.parse(response.data.response.scriptResult || '[]');
        const events = resultData.map(event => ({
          id: event.id,
          title: event.title,
          start: new Date(event.start),
          end: new Date(event.end),
          allDay: event.allDay || false,
          color: event.color,
          textColor: event.textColor,
          description: event.description,
        }));

        setPlannedEvents(events);
      } catch (error) {
        console.error('Error fetching planned hours:', error);
      }
    };

    fetchPlannedHours();
  }, [userId, token, selectedDate]);

  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: event.color || '#66B132',
      color: event.textColor || '#000',
      borderRadius: '5px',
      border: '1px solid #66B132',
      display: 'block',
      padding: '5px',
      cursor: 'pointer'
    };
    return { style };
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event); // Set clicked event for display
  };

  return (
    <div className="centered-container planned-hours-container">
      <h2>Geplande Uren</h2>
      <BigCalendar
        localizer={localizer}
        events={plannedEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600 }}
        eventPropGetter={eventStyleGetter}
        views={['month', 'week', 'day']}
        defaultView="month"
        culture="nl"
        formats={{
          timeGutterFormat: 'HH:mm',
          agendaHeaderFormat: 'DD MMM YYYY',
          dayHeaderFormat: 'dddd, DD MMMM',
          dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
            `${localizer.format(start, 'DD MMM', culture)} - ${localizer.format(end, 'DD MMM', culture)}`,
          eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
            `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`,
          agendaTimeFormat: 'HH:mm',
          agendaDateFormat: 'ddd DD-MM',
        }}
        messages={{
          month: 'Maand',
          week: 'Week',
          day: 'Dag',
          today: 'Vandaag',
          previous: 'Vorige',
          next: 'Volgende',
          agenda: 'Agenda',
          noEventsInRange: 'Geen evenementen in deze periode.',
          showMore: (count) => `+ ${count} meer`,
          date: 'Datum',
          time: 'Tijd',
          event: 'Evenement',
        }}
        onSelectEvent={handleEventClick} // Event click handler
      />

      {selectedEvent && (
        <div className="event-details">
          <h3>Details van geselecteerd evenement</h3>
          <p><strong>Titel:</strong> {selectedEvent.title}</p>
          <p><strong>Starttijd:</strong> {new Date(selectedEvent.start).toLocaleString('nl-NL')}</p>
          <p><strong>Eindtijd:</strong> {new Date(selectedEvent.end).toLocaleString('nl-NL')}</p>
          <p><strong>Omschrijving:</strong> {selectedEvent.description || 'Geen beschrijving'}</p>
          <button onClick={() => setSelectedEvent(null)}>Sluiten</button>
        </div>
      )}
    </div>
  );
}

export default React.memo(PlannedHours);
