import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import './RegisterHours.css';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';

function RegisterHours({ userId, selectedDate, setSelectedDate, token, plbToken }) {

  const [hours, setHours] = useState('');
  const [fetchedHours, setFetchedHours] = useState([]);
  const [plannedHours, setPlannedHours] = useState([]);  // New state for planned hours
  const [registeredDates, setRegisteredDates] = useState([]);
  const [displayedMonth, setDisplayedMonth] = useState(new Date());
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [showCalendar, setShowCalendar] = useState(true);
  const [pauze, setPauze] = useState(0); // In minutes
const [offertenummer, setOffertenummer] = useState('');
const [projectnummer, setProjectnummer] = useState('');


  const timeOptions = Array.from({ length: 24 * 2 }, (_, index) => {
    const hour = String(Math.floor(index / 2)).padStart(2, '0');
    const minute = index % 2 === 0 ? '00' : '30';
    return `${hour}:${minute}`;
  });

  const formatDateToLocal = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const calculateWorkedHours = (start, end) => {
    if (start && end) {
      const [startHour, startMinute] = start.split(':').map(Number);
      const [endHour, endMinute] = end.split(':').map(Number);
      const startInMinutes = startHour * 60 + startMinute;
      const endInMinutes = endHour * 60 + endMinute;
  
      // Subtract pauze (default to 0 if invalid)
      const totalMinutesWorked = Math.max(0, endInMinutes - startInMinutes - (pauze || 0));
      const difference = (totalMinutesWorked / 60).toFixed(1);
  
      setHours(difference);
    }
  };
  
  

  useEffect(() => {
    calculateWorkedHours(startTime, endTime);
  }, [startTime, endTime, pauze]);
  
  

  useEffect(() => {
    const fetchMonthlyRegisteredDates = async () => {
      const month = (displayedMonth.getMonth() + 1).toString().padStart(2, '0');
      const year = displayedMonth.getFullYear();
      const formattedDate = `${month}/${year}`;

      try {
        const response = await axios.get('https://jt.cialona.nl/fmi/data/vLatest/databases/CI_Agenda/layouts/MedewerkersUren/script/Uren_Get_All', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          params: {
            'script.param': JSON.stringify({
              date: formattedDate,
              userId: userId
            })
          }
        });

        const result = JSON.parse(response.data.response.scriptResult);
        const formattedDates = result.map(entry => {
          const localDate = new Date(entry.date);
          return `${localDate.getFullYear()}-${(localDate.getMonth() + 1).toString().padStart(2, '0')}-${localDate.getDate().toString().padStart(2, '0')}`;
        });
        setRegisteredDates(formattedDates);
      } catch (error) {
        console.error('Error fetching monthly registered dates:', error);
      }
    };

    fetchMonthlyRegisteredDates();
  }, [userId, token, displayedMonth]);

  const fetchRegisteredHours = async (date) => {
    const formattedDate = formatDateToLocal(date);
    try {
      const response = await axios.get('https://jt.cialona.nl/fmi/data/vLatest/databases/CI_Agenda/layouts/MedewerkersUren/script/Uren_Get', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          'script.param': JSON.stringify({
            userId,
            date: formattedDate
          })
        }
      });

      const hoursData = JSON.parse(response.data.response.scriptResult);
      setFetchedHours(hoursData.length > 0 ? hoursData : [{ date: formattedDate, hours: 0 }]);
    } catch (error) {
      console.error('Error fetching registered hours:', error);
    }
  };

  const fetchPlannedHoursForDay = async (date) => {
    const formattedDate = formatDateToLocal(date);
    try {
      const response = await axios.get('https://jt.cialona.nl/fmi/data/vLatest/databases/CI_PLB/layouts/ENDPOINTEVT/script/APP_GETUREN_DAY', {
        headers: {
          Authorization: `Bearer ${plbToken}`,  // Use plbToken here
          'Content-Type': 'application/json'
        },
        params: {
          'script.param': JSON.stringify({
            userId,
            date: formattedDate
          })
        }
      });
      const plannedData = JSON.parse(response.data.response.scriptResult || '[]');
      console.log(plannedData)
      setPlannedHours(plannedData);
    } catch (error) {
      console.error('Error fetching planned hours:', error);
    }
  };
  
  

  useEffect(() => {
    if (selectedDate) {
      fetchRegisteredHours(selectedDate);
      fetchPlannedHoursForDay(selectedDate);  // Fetch planned hours for selected date
    }
  }, [selectedDate, userId]);

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
      return registeredDates.includes(dateString) ? 'highlighted-date' : null;
    }
  };

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setDisplayedMonth(activeStartDate);
  };

  const submitHours = async (e) => {
    e.preventDefault();
    const formattedDate = formatDateToLocal(selectedDate);
  
    try {
      const response = await axios.get('https://jt.cialona.nl/fmi/data/vLatest/databases/CI_Agenda/layouts/MedewerkersUren/records', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          script: 'SubmitHoursScript',
          'script.param': JSON.stringify({
            userId,
            date: formattedDate,
            startTime,
            endTime,
            hours: parseFloat(hours),
            pauze: parseInt(pauze),
            offertenummer,
            projectnummer
          })
        }
      });
  
      if (response.data.messages[0].code === "0") {
        alert('Uren zijn geregistreerd');
        setRegisteredDates([...registeredDates, formattedDate]);
        fetchRegisteredHours(selectedDate);
      } else {
        alert('Error executing script: ' + response.data.messages[0].message);
      }
    } catch (error) {
      console.error('Error executing script:', error);
      alert(`An error occurred: ${error.message}`);
    }
  };
  

  return (
    <div className="register-container">
      <h2>Cialona Uren Registratie</h2>
      <button onClick={() => setShowCalendar(!showCalendar)} className="toggle-calendar-button">
        {showCalendar ? 'Verberg Kalender' : 'Toon Kalender'}
      </button>

      {showCalendar && (
        <div className="calendar-container">
          <Calendar
            onChange={setSelectedDate}
            value={selectedDate}
            tileClassName={tileClassName}
            onActiveStartDateChange={handleActiveStartDateChange}
          />
        </div>
      )}
      <form onSubmit={submitHours} className="register-form">
        <div className="input-group">
          <label htmlFor="startTime">Starttijd</label>
          <select id="startTime" value={startTime} onChange={(e) => setStartTime(e.target.value)}>
            <option value="">Selecteer tijd</option>
            {timeOptions.map((time, index) => (
              <option key={index} value={time}>{time}</option>
            ))}
          </select>
        </div>

        <div className="input-group">
          <label htmlFor="endTime">Eindtijd</label>
          <select id="endTime" value={endTime} onChange={(e) => setEndTime(e.target.value)}>
            <option value="">Selecteer tijd</option>
            {timeOptions.map((time, index) => (
              <option key={index} value={time}>{time}</option>
            ))}
          </select>
        </div>
        <div className="input-group">
  <label htmlFor="pauze">Pauze (minuten)</label>
  <input
    type="number"
    id="pauze"
    value={pauze}
    onChange={(e) => setPauze(parseInt(e.target.value) || 0)}
  />
</div>

        <div className="input-group">
          <label htmlFor="hours">Gewerkte uren</label>
          <input type="number" id="hours" value={hours} readOnly />
        </div>
        <div className="input-group">
  <label htmlFor="offertenummer">Offertenummer</label>
  <input
    type="text"
    id="offertenummer"
    value={offertenummer}
    onChange={(e) => setOffertenummer(e.target.value)}
  />
</div>
<div className="input-group">
  <label htmlFor="projectnummer">Projectnummer</label>
  <input
    type="text"
    id="projectnummer"
    value={projectnummer}
    onChange={(e) => setProjectnummer(e.target.value)}
  />
</div>
        
        <button type="submit" className="register-button">Registreer Uren</button>
      </form>
{fetchedHours.length > 0 && (
  <div className="registered-hours-container">
    <h3>Geregistreerde uren voor {selectedDate.toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' })}</h3>
    <div className="hours-table-container">
      <table className="hours-table">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Starttijd</th>
            <th>Eindtijd</th>
            <th>Gewerkte uren</th>
          </tr>
        </thead>
        <tbody>
          {fetchedHours.map((entry, index) => (
            <tr key={index}>
              <td>{new Date(entry.date).toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
              <td>{entry.startTime || '-'}</td>
              <td>{entry.endTime || '-'}</td>
              <td>{parseFloat(entry.hours).toFixed(1)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="total-hours">
      <strong>Totaal (uren):</strong> {fetchedHours.reduce((total, entry) => total + parseFloat(entry.hours || 0), 0).toFixed(1)}
    </div>
  </div>
)}

{plannedHours.length > 0 && (
  <div className="registered-hours-container">
    <h3>Geplande uren voor {selectedDate.toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' })}</h3>
    <div className="hours-table-container">
      <table className="hours-table">
        <thead>
          <tr>
            <th>Starttijd</th>
            <th>Eindtijd</th>
            <th>Omschrijving</th>
          </tr>
        </thead>
        <tbody>
          {plannedHours.map((event, index) => (
            <tr key={index}>
              <td>{new Date(event.start).toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit' })}</td>
              <td>{new Date(event.end).toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit' })}</td>
              <td>{event.title || 'Geen beschrijving'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
)}
    </div>
  );
}

export default RegisterHours;
